import { createAction } from 'redux-actions';
import { notification } from 'antd';

import { normalizeRows } from 'artist/normalization/artist-shema';

import { Request } from 'shared/tools/request';
import { createSuccessAction } from 'shared/helpers/create-success-action';

import {
  createSetPageAction,
  createSetFiltersAction,
} from 'shared/helpers/create-set-options-actions';
import moment from "moment";

export const getArtistRequest = createAction('RAW_ARTISTS_REQUEST');
export const getArtistSuccess = createSuccessAction('RAW_ARTISTS_SUCCESS');
export const getArtistError = createAction('RAW_ARTISTS_ERROR');

export const getArtists = (artistToEditId = null) => async (dispatch, getState) => {
  dispatch(getArtistRequest());

  try {
    const {
      artists: { offset, filters, sortDir, sortBy },
    } = getState();

    const params = {
      offset,
      ...filters,
    };

    const { data } = await Request.post('/api/artist', {
      data: sortDir
        ? {
            ...params,
            sortBy,
            sortDir,
          }
        : { ...params },
    });

    const {
      entities: { items },
      result: { count, rows },
    } = normalizeRows(data);

    dispatch(getArtistSuccess(items, rows, count, artistToEditId));
  } catch (err) {
    dispatch(getArtistError());
  }
};

export const setPage = createSetPageAction('SET_PAGE_ARTIST');

export const setSortDirection = createAction('SET_SORT_DIRECTION_ARTIST', sortDir => ({ sortDir }));
export const setSortBy = createAction('SET_SORT_BY_ARTIST', sortBy => ({ sortBy }));

export const setFilters = createSetFiltersAction('SET_FILTERS_ARTIST');

export const setArtistToEdit = createAction('SET_ARTIST_TO_EDIT ', artistToEdit => ({
  artistToEdit,
}));

export const navigateToPrevArtist = () => async (dispatch, getState) => {
  let {
    artists: { artistToEdit, currentPage, ids, items },
  } = getState();

  const currentLotIndex = ids.indexOf(artistToEdit.id);

  let neededLotId = ids[currentLotIndex - 1];

  if (!neededLotId && currentPage > 1) {
    dispatch(setPage(currentPage - 1));
    await dispatch(getArtists());

    ({
      artists: { artistToEdit, currentPage, ids, items },
    } = getState());

    neededLotId = ids[ids.length - 1];
  }
  dispatch(setArtistToEdit(items[neededLotId]));
};

export const navigateToNextArtist = () => async (dispatch, getState) => {
  let {
    artists: { artistToEdit, currentPage, pagesCount, ids, items },
  } = getState();

  const currentLotIndex = ids.indexOf(artistToEdit.id);

  let neededLotId = ids[currentLotIndex + 1];

  if (!neededLotId && currentPage < pagesCount) {
    dispatch(setPage(currentPage + 1));
    await dispatch(getArtists());

    ({
      lots: { artistToEdit, currentPage, pagesCount, ids, items },
    } = getState());

    [neededLotId] = ids;
  }
  dispatch(setArtistToEdit(items[neededLotId]));
};

export const updateArtistRequest = createAction('UPDATE_ARTIST_REQUEST');
export const updateArtistSuccess = createAction('UPDATE_ARTIST_SUCCESS');
export const updateArtistError = createAction('UPDATE_ARTIST_ERROR');

export const updateArtist = (id, fields) => async (dispatch, getState) => {
  dispatch(updateArtistRequest());

  const { items } = getState().artists;

  const artist = items[id];

  const requests = [];

  const { url_full, ...data } = fields;
  const images = url_full.fileList;

  if (images.length && (!artist.url_full || artist.url_full !== images[0].url)) {
    const file = images[0];

    const fileData = new FormData();
    fileData.append('file', file.originFileObj);

    requests.push(Request.post(`/api/artist/${id}/image`, { data: fileData }));
  }

  if (!images.length && artist.url_full) {
    requests.push(Request.delete(`/api/artist/${id}/image`));
  }

  try {
    await Promise.all(requests);

    const updateResult = await Request.put(`/api/artist/${id}`, {
      data: {
        ...data,
        ulan_id: data.ulan_id || null,
        ulan_alternate_spellings: data.ulan_alternate_spellings ? data.ulan_alternate_spellings.split('\n') : null,
        ulan_alternate_nationalities: data.ulan_alternate_nationalities ? data.ulan_alternate_nationalities.split('\n'): null,
        ulan_alternate_birth: data.ulan_alternate_birth ? data.ulan_alternate_birth.split('\n') : null,
      },
    });

    dispatch(getArtists(id));
    if (!updateResult) {
      dispatch(updateArtistError());
      return notification.error({
        message: 'Error',
        description: 'Something went wrong.',
      });
    }

    dispatch(updateArtistSuccess());
    notification.success({
      message: 'Success',
      description: 'Artist was successfully updated.',
    });
  } catch (err) {
    dispatch(updateArtistError());

    notification.error({
      message: 'Error',
      description: 'Something went wrong.',
    });
  }
};

export const openCreateArtistModal = createAction('OPEN_CREATE_ARTIST_MODAL');
export const closeCreateArtistModal = createAction('CLOSE_CREATE_ARTIST_MODAL');

export const createArtistRequest = createAction('CREATE_ARTIST_REQUEST');
export const createArtistSuccess = createAction('CREATE_ARTIST_SUCCESS');
export const createArtistError = createAction('CREATE_ARTIST_ERROR');

export const createArtist = fields => async dispatch => {
  try {
    dispatch(createArtistRequest());

    const currentYear = moment().year();
    if (fields.birth > currentYear || fields.death > currentYear) {
      notification.error({
        message: 'Error',
        description: 'Artist not created, invalid date',
      });
      return dispatch(createArtistError());
    }

    const { url_full: artistImages, ...newArtist } = fields;

    const images = artistImages.fileList;

    let url_full = null;
    let url_thumb = null;

    if (images.length) {
      const file = images[0];

      const fileData = new FormData();
      fileData.append('file', file.originFileObj);

      ({
        data: { url_full, url_thumb },
      } = await Request.post(`/api/artist/image`, { data: fileData }));
    }

    await Request.post(`/api/artist/create`, { data: { ...newArtist, url_full, url_thumb } });

    notification.success({
      message: 'Success',
      description: 'Artist was successfully created.',
    });

    dispatch(closeCreateArtistModal());
    dispatch(createArtistSuccess());

    await dispatch(getArtists());
  } catch (err) {
    notification.error({
      message: 'Error',
      description: 'Something went wrong.',
    });

    dispatch(createArtistError());
  }
};

export const mergeArtistRequest = createAction('MERGE_ARTIST_REQUEST');
export const mergeArtistSuccess = createAction('MERGE_ARTIST_SUCCESS');
export const mergeArtistError = createAction('MERGE_ARTIST_ERROR');
export const openMergeArtistModal = createAction('OPEN_MERGE_ARTIST_MODAL');
export const closeMergeArtistModal = createAction('CLOSE_MERGE_ARTIST_MODAL');

export const mergeArtist = fields => async dispatch => {
  try {
    dispatch(mergeArtistRequest());

    delete fields.id;
    delete fields.name;
    delete fields.nationality;
    delete fields.birth;
    delete fields.artist_name1;
    delete fields.artist_name2;
    delete fields.active_date;
    delete fields.death;
    delete fields.alternate_spellings;
    delete fields.birth_death_type;
    delete fields.Qualifier;
    delete fields.qualified_artist_id;

    const mergeResult = await Request.post(`/api/artist/merge`, { data: fields });

    if (!mergeResult) {
      dispatch(mergeArtistError());
      return notification.error({
        message: 'Error',
        description: 'Something went wrong.',
      });
    }
    notification.success({
      message: 'Success',
      description: 'Artist was successfully merged.',
    });

    dispatch(closeMergeArtistModal());
    dispatch(mergeArtistSuccess());

    await dispatch(getArtists());
  } catch (err) {
    notification.error({
      message: 'Error',
      description: 'Something went wrong.',
    });

    dispatch(mergeArtistError());
  }
};

export const getUlanData = ulanId => async (dispatch, getState) => {
  const {
    artists: { artistToEdit },
  } = getState();

  if (!ulanId) {
    return dispatch(
      setArtistToEdit({
        ...artistToEdit,
        ulan_id: undefined,
        ulan_alternate_spellings: undefined,
        ulan_preferred_birth: undefined,
        ulan_preferred_nationality: undefined,
        ulan_alternate_nationalities: undefined,
        ulan_alternate_birth: undefined,
      }),
    );
  }
  try {
    const ulanData = await Request.post(`/api/artist/artist-ulan-data`, { data: { ulan_id: ulanId } });

    if (!ulanData) {
      return notification.error({
        message: 'Error',
        description: 'Something went wrong.',
      });
    }

    dispatch(
      setArtistToEdit({
        ...artistToEdit,
        ...ulanData.data,
      }),
    );

    notification.success({
      message: 'Success',
      description: 'Artist was successfully retrieved from ULAN.',
    });
  } catch (err) {
    notification.error({
      message: 'Error',
      description: 'Something went wrong.',
    });
  }
};

export const setToInitialState = createAction('ARTIST_TO_INITIAL_STATE');
